import { FormProvider, useForm } from "react-hook-form";
import Countdown from "./CountDown";
import axios from "axios";
import { toast } from "react-toastify";

const emailValidation = {
  required: {
    value: true,
    message: "Email Is Required",
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Not Valid Email",
  },
};

const agreeTosAndPpValidation = {
  required: {
    value: true,
    message: "required",
  },
};

const ComingSoon = () => {
  const methods = useForm({
    defaultValues: {
      email: "",
      "agree-tos-pp": true,
    },
  });
  const {
    register,
    formState: { errors },
  } = methods;

  const inputErrors = errors?.email?.message;

  const submitHandler = methods.handleSubmit(async (data) => {
    try {
      // await axios.post("http://localhost:5000/sub-user", {
      //   userEmail: data.email,
      // });
      toast.success(
        "Congratulations! 🎉 You've successfully subscribed to our join list."
      );
      methods.reset();
    } catch (error) {
      toast.error(
        "Oops! It seems there was a problem subscribing you to our join list. Please try again later."
      );
    }
  });
  const endDate = "2024-12-15";
  const endTime = "00:00:00";

  return (
    <>
      <section className="container">
        <header>Launching Soon: Your Gateway to Exclusive Auctions!</header>
        <p>
          Our platform is set to unveil an unparalleled experience, offering you
          access to a world of unique auctions. Immerse yourself in a curated
          selection of rare finds, collectibles, and coveted items. Prepare to
          bid, win, and discover your next prized possession! Join us as we
          redefine the art of online auctions.
        </p>
        <Countdown endDate={endDate} endTime={endTime} />
        <div className="email-content">
          <FormProvider {...methods} className="email-content">
            <form className="" onSubmit={(e) => e.preventDefault()}>
              <div className="input-box">
                <input
                  className={`input ${inputErrors && "inputErrors"}`}
                  type={"email"}
                  id={"email"}
                  name={"email"}
                  placeholder={`Enter Email`}
                  {...register("email", emailValidation)}
                />
                <button onClick={submitHandler}>Join waitlist</button>
              </div>
              <div className="agree-tos-pp-container">
                <div>
                  <input
                    {...methods.register(
                      "agree-tos-pp",
                      agreeTosAndPpValidation
                    )}
                    type="checkbox"
                    name="agree-tos-pp"
                    id="agree-tos-pp"
                  />
                  <label
                    htmlFor="agree-tos-pp"
                    className={` ${
                      methods?.formState?.errors["agree-tos-pp"]?.message &&
                      "input-field-error"
                    }`}
                  >
                    I confirm that I have read and understood BidandGo.it terms
                    and condition{" "}
                    <a
                      style={{
                        color: "white",
                        marginRight: "4px",
                      }}
                      target="_blank"
                      href="https://join.bidandgo.it/term-of-service"
                      className="agree-link"
                      rel="noreferrer"
                    >
                      Terms of Service
                    </a>
                    and{" "}
                    <a
                      style={{
                        color: "white",
                        marginRight: "4px",
                      }}
                      target="_blank"
                      href="https://join.bidandgo.it/privacy-policy"
                      className="agree-link"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </label>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
